<template>
    <div>
        <div class="box_title flex" style="justify-content: space-between;">
            <Title title="填写稿件内容" :backShow="false" />
            <div class="flex">
                <button @click.stop="back" class="but">返回</button>
                <button @click.stop="choose" class="but">选取草稿</button>
            </div>
        </div>
        <div class="ct_box">
            <!-- 封面 -->
            <upLoadImg v-if="$store.state.FillForm.category_id == 4" :imageUrl="$store.state.FillForm.cover_image"
                title="封面" @getImg="getImg" />
            <upLoadVideo v-if="$store.state.FillForm.category_id == 6 || $store.state.FillForm.category_id == 7"
                title="上传视频" @getVideo="getVideo" />
            <div class="flex input_box" style="justify-content:flex-start"
                v-show="$store.state.FillForm.category_id == 7">
                <div class="title_box">
                    <p class="title">视频预览</p>
                </div>
                <video :src="$store.state.FillForm.video_url" controls="controls" class="contentVideo">
                    您的浏览器不支持 video 标签。
                </video>
            </div>

            <div class="flex input_box" v-show="$store.state.FillForm.video_url">
                <div class="title_box">
                    <p class="title">视频预览</p>
                </div>
                <video :src="$store.state.FillForm.video_url" controls="controls" class="video">
                    您的浏览器不支持 video 标签。
                </video>
            </div>
            <div class="flex input_box">
                <div class="title_box">
                    <p class="title"><span>*</span>稿件标题</p>
                </div>
                <input maxlength="25" class="input_text" v-model="$store.state.FillForm.title"
                    placeholder="标题建议25字符以内，字符过多的，媒介修改标题不通知" />
                <span class="num">{{ $store.state.FillForm.title.length }}/50</span>
            </div>
            <fillTimer @get_timer="get_timer" />
            <div class="flex input_box">
                <div class="title_box">
                    <p class="title">转载链接</p>
                </div>
                <input class="input_text" v-model="$store.state.FillForm.url" placeholder="请输入转载链接" />
            </div>
            <div class="flex input_box">
                <div class="title_box">
                    <p class="title">备注信息</p>
                </div>
                <input class="input_text" v-model="$store.state.FillForm.remark" placeholder="请输入备注信息" />
            </div>
            <div>
                <div class="flex input_box2">
                    <div class="title_box">
                        <p class="title"><span></span>上传文档</p>
                    </div>
                    <div class="upload_box">
                        <el-upload accept="pdf,doc,docx" :show-file-list="true" @on-success="uploadFn" @on-error="err"
                            :limit="1" class="upload-demo" drag :action='uploadAPI' :data=formData
                            :on-success="handleAvatarSuccess" :headers="headers" :multiple="false"
                            :before-upload="beforeAvatarUpload">
                            <div class="el-upload__text">
                                <!-- <p class="text">将文件拖拽到此区域上传</p> -->
                                <img src="@/assets/wordOp/upload.png" alt="" style="margin: 1rem 0" />
                                <div class="el-upload__tip" slot="tip">
                                    仅支持.docx文件，Office2007、2010、20132016
                                </div>
                                <div class="el-upload__tip" slot="tip">
                                    文件大小不可超过10MB!
                                </div>
                            </div>
                            <!-- <i class="el-icon-upload"></i> -->
                            <div class=""></div>
                            <!-- <em>点击上传</em> -->
                        </el-upload>
                    </div>
                </div>
                <div class="flex input_box">
                    <div class="title_box2">
                        <p class="title"><span></span>稿件内容</p>
                    </div>
                    <div style="width: 70%;">
                        <vue-ueditor-wrap v-model="$store.state.FillForm.content" :config="myConfig"></vue-ueditor-wrap>
                    </div>
                </div>
                <div class="input_box flex">
                    <div class="title_box">
                        <p class="title"><span></span></p>
                    </div>
                    <button class="draft_but" @click="adddRaft">存为草稿</button>
                </div>
            </div>
        </div>
        <SetMenuButNav />
    </div>
</template>

<script>
import Title from '@/components/UserInfoTitle.vue'
import VueUeditorWrap from 'vue-ueditor-wrap'
import SetMenuButNav from '@/components/SetMenuButNav.vue'
import { mapState, mapMutations } from 'vuex'
import { fill_title_rules } from '@/util/fill_title_rules'
import fillTimer from '@/components/fillTimer'
export default {
    name: '',
    computed: {
        ...mapState(['FillForm'])
    },

    components: {
        Title,
        VueUeditorWrap,
        SetMenuButNav,
        fillTimer
    },
    mounted() {
        this.headers = {
            'token': this.$user_info.token
        }
        this.getList()
    },
    data() {
        return {
            myConfig: {
                // 编辑器不自动被内容撑高
                autoHeightEnabled: false,
                // 初始容器高度
                initialFrameHeight: 400,
                // 初始容器宽度
                initialFrameWidth: '90%',
                // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
                serverUrl: `/api/common/getupload?token=${this.$user_info.token}`,
                // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
                UEDITOR_HOME_URL: '/UEditor/',
                initialContent: '1.所提供文章必须符合国家广告法内容，若文章存在违规被删除，本平台不予处理；<br/>2.Word文档上传后请仔细检查内容是否有误，一经发布无法撤回和修改，请确认后再操作；<br/>3.署名、作者名、免责声明等特殊内容不保证，网媒没标明不包时效的，默认1个月。',
                autoClearinitialContent: true,
                toolbars: [
                    [
                        "fullscreen",
                        "source",	// html 源码
                        "|",
                        "undo",
                        "redo",
                        "|",
                        "link",
                        "unlink",
                        "|",
                        "simpleupload",
                        "insertimage",
                        "insertvideo",
                        "|",
                        "bold",
                        "forecolor",
                        "|",
                        "justifyleft",
                        "justifycenter",
                        "justifyright",
                        "justifyjustify",
                        "|",
                        "fontfamily",
                        "fontsize",
                        "|",
                        "removeformat",
                        "formatmatch",
                        "autotypeset",
                    ]
                ],
            },
            uploadAPI: `/api/common/upload`,
            headers: {},
            formData: {
                type: 'read'
            },
        }
    },
    methods: {
        get_timer(time) {
            this.$store.state.FillForm.limit_time = time
        },
        getImg(url) {
            this.$store.state.FillForm.cover_image = url
        },
        getVideo(url) {
            this.$store.state.FillForm.video_url = url
            console.log(this.$store.state.FillForm.video_url);
            if (this.$route.query.id == 6) {
                this.$store.state.FillForm.content += `<video controls="controls" src="${url}" class="contentVideo"></video>`
                // let d = document.getElementById("contentVideo");
                // console.log(d);
            }
        },
        handleAvatarSuccess(res, file) {
            console.log(res);
            if (res.code == 1) {
                this.$store.state.FillForm.content = res.data.content
                this.$store.state.FillForm.url = res.data.url
            }

        },
        beforeAvatarUpload(file) {
            let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);

            const extension = testmsg === "pdf" || testmsg === "doc" || testmsg === "docx";

            if (!extension) {
                this.$message.error('上传图片类型只能是 PDF,DOC,DOCX!')
            }
            const is10M = file.size / 1024 / 1024 < 10;
            //限制文件上传大小
            if (!is10M) {
                this.$message.error("上传图片大小不能超过 10MB!");
                return false
            }
            return extension
        },
        handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
                } 个文件`
            )
        },
        uploadFn(response, file, fileList) {
            console.log(response, file, fileList)
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}?`)
        },
        err(err, file, fileList) {
            console.log(err, file, fileList)
        },
        timerCheck() {
            console.log(this.$store.state.FillForm);
        },
        // 选取草稿
        choose() {
            this.$store.commit('childTab', '草稿箱')
            return this.$router.push('/user/draft')
        },
        getList() {
            if (this.$route.query.itemId) {
                this.curlGet('/api/users/draft/detail', {
                    id: this.$route.query.itemId,
                }).then(res => {
                    if (res.data.code) {
                        // this.$store.commit('shopPush', res.data.data)
                        this.$store.state.FillForm = res.data.data[0]
                        if (!this.$store.state.FillForm.limit_time) {
                            this.$set(this.$store.state.FillForm, 'limit_time', '')
                        }
                    }
                })
            }
            // console.log(this.$store.state.shopObj);
        },
        back() {
            this.$store.commit('childTab', '媒介资源')
            switch (this.$route.query.id) {
                case '1':
                    this.$router.push({
                        path: '/user/userMedia/News',
                    })
                    break;
                case '2':
                    this.$router.push({
                        path: '/user/userMedia/UserWeMedia',
                    })
                    break;
                case '3':
                    this.$router.push({
                        path: '/user/userMedia/UserPaperMedia',
                    })

                    break;
                case '4':
                    this.$router.push({
                        path: '/user/userMedia/UserOfficialAccounts',
                    })
                    break;
                case '5':
                    this.$router.push({
                        path: '/user/userMedia/UserWb',

                    })

                    break;
                case '6':
                    this.$router.push({
                        path: '/user/userMedia/UserRedBook',

                    })

                    break;
                case '7':
                    this.$router.push({
                        path: '/user/userMedia/UserShortVideo',

                    })
                    break;
                case '0':
                    this.$store.commit('childTab', '媒介套餐')
                    this.$router.push({
                        path: '/user/SetMenu/SetMenuList',

                    })

                    break;
                default:
                    break;
            }

        },
        // 存为草稿
        adddRaft() {
            const titleStatus = fill_title_rules(this.$store.state.FillForm.title)
            if (!titleStatus) {
                return
            }
            if (this.$store.state.FillForm.title == '') {
                return this.$message({
                    message: '请填写标题',
                    type: 'warning',
                })
            }
            let data = {}
            data.id = this.$route.query.id
            if (this.$store.state.shopObj[0]) {
                data = this.$store.state.shopObj[0].category_id
            } else {
                data.category_id = 0
            }
            if (this.$store.state.FillForm.limit_time != '') {
                data.limit_time = this.$store.state.FillForm.limit_time / 1000
            }
            if (this.$store.state.FillForm.url != '') {
                data.url = this.$store.state.FillForm.url
            }
            if (this.$store.state.FillForm.content != '') {
                data.content = this.$store.state.FillForm.content
            }
            if (this.$store.state.FillForm.remark != '') {
                data.remark = this.$store.state.FillForm.remark
            }
            data.medium_ids = this.$store.state.FillForm.medium_ids
            data.package_ids = this.$store.state.FillForm.package_ids
            data.title = this.$store.state.FillForm.title
            this.curlPost('/api/users/draft/add', data).then(res => {
                console.log(res);
                if (res.data.code) {
                    this.$message({
                        message: '添加草稿成功',
                        type: 'success',
                    })
                    return this.$router.push('/user/draft')
                }
            })
        }
    },
}
</script>

<style lang=scss scoped>
@import '../../scss/Fill.scss';
</style>